import React, {useState} from "react";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import CustomLocalizedLink from "../../components/locales/custom-localized-link";
const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={'Digilock Security Center'}
            description={''}
        />
        <div className="toc security">
          <section className="hero">
            <div className="container">
              <h1>
                Security Center
              </h1>
              <p>
                Welcome to the Digilock Security Center. We take security seriously and are continuously working to strengthen security across our products.
              </p>
              <p>
                This page is designed to provide a destination for reporting and addressing security notices regarding Digilock products. When vulnerabilities are reported, we immediately begin working to identify, analyze and respond to known vulnerabilities.
              </p>
              <h2>Digilock Security Advisories </h2>
              <p><span>Security Concern:  Data extraction from Digilock locks and electronic keys</span></p>
              <p>Digilock has become aware of efforts by a security researcher to identify and access vulnerabilities in its legacy locks.  These efforts reportedly began in 2018, and after six years, the results were only recently published.  The vulnerability was only identified after significant efforts including, for example, dissecting the locks and the keys at a component level, soldering wires to the various boards and chips, and creating a custom circuit board.</p>
              <p>Exploitation of the vulnerability requires access to and disassembly of a Digilock lock installed at a customer site.  Audit trail data and the end user four-digit PIN code may be extracted, and the site-specific electronic key may be duplicated. The duplicated key can easily be overridden and rendered inoperable by implementing a substitute manager key. </p>
              <p>
                Additional security implementation(s) on locks currently available for purchase may include:
                <ul>
                  <li>Implementation of code protection on additional data blocks;</li>
                  <li>Additional encryption of data communicated between lock and key; and</li>
                  <li>Additional encryption of EEPROM data at rest.</li>
                </ul>
              </p>
              <p>
                There is no evidence that this vulnerability has ever been exploited at an existing customer site.  If you would like to discuss this further, <CustomLocalizedLink goto={'/support/#contactInfo'} label={'please contact'} /> your Digilock representative.
              </p>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
